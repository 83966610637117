import { contractStates, contractTypes } from './Contract'

import Model from './Model'

export const w9States = {
  HAS_VALID_ABOUND_W9: 'has_valid_abound_w9',
  HAS_VALID_ABOUND_W9_WITH_SIGNATURE: 'has_valid_abound_w9_with_signature',
  HAS_W9: 'has_w9',
  NEEDS_W9: 'needs_w9',
  W9_NOT_REQUIRED: 'w9_not_required',
}

class User extends Model {
  constructor (initialData) {
    super(initialData, {
      momentKeys: [
        'created_at',
        'updated_at',
      ],
    })
  }

  get isDeactivated () {
    return this.suspended
  }

  get isAgencyUser () {
    return this.user_type === 'agency'
  }

  get isBusinessUser () {
    return this.user_type === 'business'
  }

  get fullName () {
    return `${this.first_name} ${this.last_name}`
  }

  get fullNameLastFirst () {
    return `${this.last_name}, ${this.first_name}`
  }

  get hasValidAboundW9WithSignatureCached () {
    return this.cached_metadata && !!this.cached_metadata.has_valid_abound_w9_with_signature
  }

  get hasValidAboundW9Cached () {
    return this.cached_metadata && !!this.cached_metadata.has_valid_abound_w9
  }

  get hasAnyAboundW9Cached () {
    return this.cached_metadata && !!this.cached_metadata.has_any_abound_w9
  }

  get hasValidW9Cached () {
    return this.cached_metadata && !!this.cached_metadata.has_valid_w9
  }

  get cachedOcacInsuranceLloydsOfLondonContractStatus () {
    return this.cached_metadata &&
      this.cached_metadata.contracts &&
      this.cached_metadata.contracts[contractTypes.OCAC_INSURANCE_LLOYDS_OF_LONDON]
  }

  get hasSignedOcacInsuranceLloydsOfLondonContractCached () {
    return this.cachedOcacInsuranceLloydsOfLondonContractStatus === contractStates.SIGNED
  }

  get prettyPermissionSetNameArray () {
    return this.cached_agency_permission_set_names.map(ps => ps.name.replace('Agency ', ''))
  }

  get currentW9State () {
    if (!this.showTaxCenter) {
      return w9States.W9_NOT_REQUIRED
    } else if (this.hasValidAboundW9WithSignatureCached) {
      return w9States.HAS_VALID_ABOUND_W9_WITH_SIGNATURE
    } else if (this.hasValidAboundW9Cached) {
      return w9States.HAS_VALID_ABOUND_W9
    } else if (this.hasAnyAboundW9Cached || this.hasValidW9Cached) {
      return w9States.HAS_W9
    } else {
      return w9States.NEEDS_W9
    }
  }

  get showTaxCenter () {
    return this.cached_metadata.show_tax_center
  }
}

export default User
