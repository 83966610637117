import 'moment-timezone'

import { DEFAULT_HTML_TITLE, HTML_TITLE_SUFFIX, paymentMethodTypes } from './constants'
import { camelCase, filter, includes, reject, startCase } from 'lodash'

import getCssVariable from './GetCssVariable'
import moment from 'moment'
import windowVariables from './WindowVariables'

export const WindowVariables = windowVariables
export const GetCssVariable = getCssVariable

export function DropdownSelectFromArray (idPrefix, arr) {
  return arr.map(item => {
    return {
      id: `${idPrefix}-${item}`,
      label: startCase(camelCase(item)),
      val: item,
    }
  })
}

/**
 * Round the supplied number to a specific number of decimals, unless it is a whole number.
 *
 * @param {number} num - Number to round
 * @param {number} [places] - Number of places to round, if number is not whole. (default: 2)
 */
export function RoundIfNotWhole (num, places = 2) {
  const numFixed = num.toFixed(places)
  // Check that the fixed place isn't just trailing 0's
  return numFixed % 1 === 0 ? num.toFixed(0) : numFixed
}

export const UserStateTransformations = {
  convertEventState (state) {
    if (state === 'awaiting_payment_settlement') {
      return 'Paid - Awaiting Settlement'
    } else if (state === 'attempting_distribution') {
      return 'Distribution in Progress'
    } else if (state === 'requested') {
      return 'Requested'
    } else if (state === 'accepted') {
      return 'Accepted'
    } else if (state === 'rejected') {
      return 'Rejected'
    } else if (state === 'estimated') {
      return 'Estimated'
    } else if (state === 'estimate_approved') {
      return 'Estimate Approved'
    } else if (state === 'estimate_declined') {
      return 'Estimate Declined'
    } else if (state === 'bidding_open') {
      return 'Bidding Open'
    } else if (state === 'bidding_closed') {
      return 'Bidding Closed'
    } else if (state === 'scheduled') {
      return 'Scheduled'
    } else if (state === 'active') {
      return 'Active'
    } else if (state === 'finished') {
      return 'Finished'
    } else if (state === 'invoiced') {
      return 'Invoiced'
    } else if (state === 'attempting_payment') {
      return 'Attempting Payment'
    } else if (state === 'paid') {
      return 'Paid'
    } else if (state === 'payment_error') {
      return 'Payment Error'
    } else if (state === 'distributed') {
      return 'Distributed'
    } else if (state === 'distribution_error') {
      return 'Distribution Error'
    } else if (state === 'cancelled') {
      return 'Cancelled'
    } else {
      return state
    }
  },
  convertInvoiceState (state) {
    if (state === 'awaiting_payment_settlement') {
      return 'Paid - Awaiting Settlement'
    } else if (state === 'sent') {
      return 'Sent'
    } else if (state === 'generated') {
      return 'Generated'
    } else if (state === 'attempting_payment') {
      return 'Attempting Payment'
    } else if (state === 'paid') {
      return 'Paid'
    } else if (state === 'payment_error') {
      return 'Payment Error'
    } else if (state === 'cancelled') {
      return 'Modified'
    } else {
      return state
    }
  },
  convertLedgerState (state) {
    if (state === 'new') {
      return 'Not Yet Initiated'
    } else if (state === 'settled') {
      return 'Paid'
    } else if (state === 'awaiting_settlement') {
      return 'Processing'
    } else if (state === 'in_progress') {
      return 'Processing'
    } else if (state === 'waiting_for_retry') {
      return 'Retrying'
    } else if (state === 'failed') {
      return 'Payment Failed'
    } else {
      return state
    }
  },
  convertLedgerRefundState (state) {
    if (state === 'refund_new') {
      return 'Not Yet Initiated'
    } else if (state === 'refund_settled') {
      return 'Refunded'
    } else if (state === 'refund_awaiting_settlement') {
      return 'Processing'
    } else if (state === 'refund_in_progress') {
      return 'Processing'
    } else if (state === 'refund_waiting_for_retry') {
      return 'Retrying'
    } else if (state === 'refund_failed') {
      return 'Refund Failed'
    } else if (state === 'refund_none') {
      return ''
    } else {
      return state
    }
  },
}

/**
 * Set the HTML <title> attribute. If falsey, the default title will be used.
 *
 * @param {string} [title] - String to set as the new HTML <title>
 */
export function setPageTitle (title) {
  if (title) {
    document.title = `${title} ${HTML_TITLE_SUFFIX}`
  } else {
    document.title = DEFAULT_HTML_TITLE
  }
}

// This needs to MATCH the conflict logic in the backend (assignment#conflicts_with? currently)
export function assignmentsOverlapRange (records, start, end) {
  return reject(
    filter(records, r => {
      return (
        (r.job_start_date.isSameOrAfter(start) &&
          r.job_start_date.isBefore(end)) ||
        (r.job_end_date.isAfter(start) && r.job_end_date.isSameOrBefore(end)) ||
        (r.job_start_date.isSameOrBefore(start) &&
          r.job_end_date.isSameOrAfter(end))
      )
    }),
    r => includes(['pending', 'rejected', 'cancelled'], r.aasm_state)
  )
}

export function formatTimeForReport (value, fmt = 'MM/DD/YYYY') {
  return value == null
    ? ''
    : moment
        .utc(value)
        .tz(WindowVariables.timezone)
        .format(fmt)
}

export const dashChar = '—'

export function formatDashIfBlank (val) {
  if (val && val !== '') {
    return val
  } else {
    return dashChar
  }
}

export function formatTimeDashIfBlank (val, fmt) {
  if (val && val !== '') {
    return formatTimeForReport(val, fmt)
  } else {
    return dashChar
  }
}

export function formatBoolToText (val) {
  return val ? 'Yes' : 'No'
}

/**
 * List of classes to add if the given condition is truthy
 *
 * @typedef {Object} ClassOption
 * @property {string} className - Name of the CSS class or bem suffix (not including the '--')
 * @property {*} testCondition - Function or value to determine if this CSS class is used
 * @property {boolean} bem - True if this className is actually a bem suffix
 */

/**
 * Build a string of CSS classes to add to a dom node.
 *
 * e.g. `buildClassString('base-class', {'other-class': () => true})`
 * becomes
 * `'base-class other-class'`
 *
 * @param {array|string} baseClass - Base CSS class to use for other bem-mode classes
 * @param {ClassOption[]} classOptions - Any {@link ClassOption} objects to evaluate
 */
export function buildClassString (baseClass, classOptions) {
  return classOptions.reduce((classString, classOption) => {
    if (_testConditionPasses(classOption)) {
      classString += ` ${_classNameFromClassOption(baseClass, classOption)}`
    }
    return classString
  }, baseClass)
}

function _classNameFromClassOption (baseClass, classOption) {
  if (classOption.bem) {
    return `${baseClass}--${classOption.className}`
  } else {
    return classOption.className
  }
}

function _testConditionPasses (classOption) {
  const testCondition = classOption.testCondition

  if (typeof testCondition === 'function') {
    return testCondition()
  } else {
    return !!testCondition
  }
}

/**
 * Open a specified URL in a new window/tab, automatically open the system print dialog, and then
 * close the window/tab.
 *
 * @param {string} url - URL of the page to open for printing
 */

export function openPrintDialogForUrl (url) {
  const printWindow = window.open(url)

  // Open a new window/tab, print, close window/tab.
  // From https://stackoverflow.com/a/37141437/850625
  printWindow.onload = function () {
    const isIE = /(MSIE|Trident\/|Edge\/)/i.test(navigator.userAgent)

    if (isIE) {
      printWindow.print()
      setTimeout(() => {
        printWindow.close()
      }, 100)
    } else {
      setTimeout(() => {
        printWindow.print()
        const ival = setInterval(function () {
          printWindow.close()
          clearInterval(ival)
        }, 200)
      }, 500)
    }
  }
}

/**
 * Get the name of the payment method type as a pretty string
 * @param {string} paymentMethodType String name of the PaymentMethod as stored in the database
 * @param {boolean} lowercase Whether to return the values as lowercase
 */
export function paymentMethodDisplay (paymentMethodType, lowercase = false) {
  switch (paymentMethodType) {
    case paymentMethodTypes.BANK_ACCOUNT:
      return lowercase ? 'bank account' : 'Bank Account'
    case paymentMethodTypes.CREDIT_CARD:
      return lowercase ? 'credit card' : 'Credit Card'
    case paymentMethodTypes.MANUAL:
      return lowercase ? 'manual payment method' : 'Manual Payment Method'
  }
}

export function enableBodyScroll () {
  if (document.readyState === 'complete') {
    document.body.style.position = ''

    if (document.body.style.marginTop) {
      const scrollTop = -parseInt(document.body.style.marginTop, 10)
      document.body.style.marginTop = ''
      window.scrollTo(window.pageXOffset, scrollTop)
    }
  } else {
    window.addEventListener('load', enableBodyScroll)
  }
}

export function disableBodyScroll ({ savePosition = true } = {}) {
  if (document.readyState === 'complete') {
    if (document.body.scrollHeight > window.innerHeight) {
      if (savePosition) document.body.style.marginTop = `-${window.pageYOffset}px`
      document.body.style.position = 'fixed'
    }
  } else {
    window.addEventListener('load', () => disableBodyScroll({ savePosition }))
  }
}

export function emailIsValid (email) {
  if (email) {
    // eslint-disable-next-line no-useless-escape
    return email.match(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
  } else {
    return false
  }
}

export const clientActions = {
  taxCenter: {
    ADD_W9: 'add_w9',
    ADD_W9_IF_NEEDED: 'add_w9_if_needed',
  },
}
